<template>
  <b-card class="m-auto w-60">
    <g-form @submit="save(selectedItem)">
      <b-row>
        <!-- email -->
        <b-col md="4">
          <g-field
            :value.sync="selectedItem.clientName"
            placeholder=""
            label-text="theName"
            rules="required"
            :label="$t('name')"
            id="register-username"
            name="name"
          />
        </b-col>
        <b-col md="4">
          <g-field
            :value.sync="selectedItem.domainName"
            placeholder=""
            label-text="domin"
            :label="$t('domin')"
            rules="required"
            id="register-domin"
            name="domin"
          />
        </b-col>
        <b-col md="4">
          <g-field
            :value.sync="selectedItem.email"
            placeholder=""
            label-text="email"
            rules="required|email"
            :label="$t('email')"
            id="register-username"
            name="email"
          />
        </b-col>
      </b-row>
      <b-row>
        <b-col md="4">
          <g-field
            :value.sync="selectedItem.commercialRegisterNumber"
            placeholder=""
            label-text="commercialRegisterNumber"
            :label="$t('commercialRegisterNumber')"
            rules="required"
            id="register-commercialRegisterNumber"
            name="commercialRegisterNumber"
          />
        </b-col>
        <b-col md="4">
          <g-field
            :value.sync="selectedItem.taxNumber"
            placeholder=""
            label-text="taxNumber"
            :label="$t('taxNumber')"
            rules="required"
            id="register-taxNumber"
            name="taxNumber"
          />
        </b-col>
        <b-col md="4">
          <g-field
            :value.sync="selectedItem.address"
            placeholder=""
            label-text="address"
            :label="$t('address')"
            rules="required"
            id="register-address"
            name="address"
          />
        </b-col>
      </b-row>
      <b-row>
        <b-col md="4">
          <g-field
            :value.sync="selectedItem.contactPerson"
            placeholder="contactPerson"
            label-text="contactPerson"
            :label="$t('contactPerson')"
            rules="required"
            id="register-contactPerson"
            name="contactPerson"
          />
        </b-col>
        <b-col md="4">
          <label class="h5">{{ $t('contactPhone') }}</label>
          <b-form-input
            v-model="selectedItem.contactPhone"
            type="number"
            class="mb-1"
          />
        </b-col>
        <b-col md="4">
          <b-form-group>
            <g-field
              :value.sync="selectedItem.planId"
              :dir="isRight ? 'rtl' : 'ltr'"
              label-text="planName"
              field="select"
              rules="required"
              name="plansCat"
              :options="plans"
              label="arabicName"
            />
          </b-form-group>
        </b-col>
      </b-row>
      <b-row>
        <b-col md="4">
          <!-- branches  -->
          <g-field
            id="branches"
            :value.sync="selectedItem.additionalBranches"
            label-text="additionalBranchNumber"
            rules="integer"
            name="branches"
            type="number"
          />
        </b-col>
        <b-col md="4">
          <!-- users  -->
          <g-field
            id="users"
            :value.sync="selectedItem.additionalUsers"
            label-text="additionalUserNumber"
            name="users"
            rules="integer"
            type="number"
          />
        </b-col>
        <b-col md="4">
          <b-form-group>
            <!-- users  -->
            <g-field
              id="users"
              :value.sync="selectedItem.licenseDuration"
              label-text="licenseTerm"
              name="maxCountOfUsage"
              rules="integer"
              type="number"
            />
          </b-form-group>
        </b-col>
      </b-row>
      <b-row>
        <b-col md="6">
          <b-form-group class="mb-0">
            <!-- <div class="d-flex justify-content-between">
              <label for="register-password">{{ $t('password') }}</label>
            </div> -->
            <g-field
              label="Password"
              :value.sync="selectedItem.adminPassord"
              label-text="adminPassword"
              rules="required"
              name="register-password"
              :type="passwordFieldType"
              placeholder="············"
            />
          </b-form-group>
          <password-validation
            :password="selectedItem.adminPassord"
            @validPassword="updateValidPassword"
          />
        </b-col>
        <b-col md="6">
          <b-form-group>
            <!-- <div class="d-flex justify-content-between">
              <label for="confirm-password">{{
                $t('confirmPassword')
              }}</label>
            </div> -->
            <g-field
              label="confirmPassword"
              :value.sync="selectedItem.confirmPassword"
              label-text="confirmPassword"
              rules="required"
              name="confirm-password"
              :type="passwordFieldType"
              placeholder="············"
            />
          </b-form-group>
        </b-col>
      </b-row>
      <b-row>
        <b-col md="4">
          <b-button
            type="submit"
            variant="warning"
            @click="getDataFromClipboard"
          >
            {{ $t('getDataFromClipboard') }}
          </b-button>
        </b-col>
        <b-col md="4" />
        <b-col md="4" class="text-right">
          <!-- submit buttons -->
          <b-button type="submit" variant="primary">
            {{ $t('dataSaved') }}
          </b-button>
        </b-col>
      </b-row>
      <hr />
      <b-row>
        <b-col md="4" />
        <b-col md="4" class="text-right">
          <!-- submit buttons -->
          <b-button @click="renewLicense" variant="dark">
            {{
              this.$t('renewLincesForOneYear', {
                days: selectedItem.licenseDuration,
              })
            }}
          </b-button>
        </b-col>
        <b-col md="4" />
      </b-row>
    </g-form>
  </b-card>
</template>

<script>
import { togglePasswordVisibility } from '@core/mixins/ui/forms';
import passwordValidation from '../../Settings/ChangePassword/components/passwordValidation.vue';

export default {
  components: {
    passwordValidation,
  },
  mixins: [togglePasswordVisibility],
  data() {
    return {
      valid_password: false,
      selectedItem: {
        clientName: '',
        domainName: '',
        email: '',
        commercialRegisterNumber: '',
        taxNumber: '',
        address: '',
        contactPerson: '',
        contactPhone: '',
        planId: null,
        additionalBranches: 0,
        additionalUsers: 0,
        licenseDuration: 365,
      },
      plans: [],
      copiedData: {},
    };
  },
  mounted() {
    this.getPlans();
    this.selectedItem.adminPassord = 'Admin@2030';
    this.selectedItem.confirmPassword = 'Admin@2030';
  },
  methods: {
    async getDataFromClipboard() {
      const text = await navigator.clipboard.readText();
      this.copiedData = JSON.parse(this.convertTextToJson(text));
      this.selectedItem = {
        clientName: this.copiedData['الإسم'],
        domainName: this.copiedData['الدومين'],
        email: this.copiedData['الإيميل'],
        commercialRegisterNumber: this.copiedData['السجل التجارى'],
        taxNumber: this.copiedData['الرقم الضريبى'],
        address: this.copiedData['العنوان'],
        contactPerson: this.copiedData['إسم المسئول'],
        contactPhone: this.copiedData['رقم الجوال'],
        planId: this.plans.filter(
          (x) => x.arabicName === this.copiedData['الباقة']
        )[0].id,
        additionalBranches: Number(this.copiedData['الفروع الإضافية']) || 0,
        additionalUsers: Number(this.copiedData['المستخدمين الإضافيين']) || 0,
        licenseDuration: 365,
      };
    },
    updateValidPassword(validPass) {
      this.valid_password = validPass;
    },
    getPlans() {
      this.get({ url: 'plans' }).then((data) => {
        this.plans = data;
      });
    },
    save() {
      this.create({
        url: 'Clients',
        data: this.selectedItem,
      }).then(() => {
        this.doneAlert({ text: this.$t('savedSuccessfully') });
        window.location.reload();
      });
    },
    renewLicense() {
      this.update({
        url: 'Subscriptions/renew-subscription',
        id: `${this.selectedItem.domainName}/${this.selectedItem.licenseDuration}`,
      }).then(() => {
        this.doneAlert({ text: this.$t('updatedSuccessfully') });
        window.location.reload();
      });
    },
  },
};
</script>
